<div>
  <p data-cy="textTermsModal">
    <b>Please read these terms and conditions carefully before using Our Platform.</b>
  </p>

  <h5>1. Interpretation and Definitions</h5>

  <h6>1.1 Interpretation</h6>

  <p>
    The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions
    shall have the same meaning regardless of whether they appear in singular or in plural.
  </p>

  <h6>1.2 Definitions</h6>

  <p>For the purposes of these Terms and Conditions:</p>

  <p><b>Application</b> means the software program provided by the Company downloaded by You on any electronic device, named Paidiem.</p>

  <p>
    <b>Application Store</b> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc.
    (Google Play Store) in which the Application has been downloaded.
  </p>

  <p>
    <b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a party, where control means ownership
    of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing
    authority.
  </p>

  <p><b>Country</b> refers to Canada.</p>

  <p><b>Company</b> (referred to as either the Company, We, Us or Our in this Agreement) refers to Paidiem Payment Solutions Inc.</p>

  <p><b>Device</b> means any device that can access the Platform such as a computer, a cellphone or a digital tablet.</p>

  <p>
    <b>Feedback</b> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our
    Platform.
  </p>

  <p><b>Platform</b> refers to the Application or the Website or both.</p>

  <p><b>Requests for Prepayment</b> means a request by You for Us to process a Prepayment on the Platform.</p>

  <p><b>Services</b> refer to the services available on the Platform.</p>

  <p><b>Subscriptions</b> refer to the services or access to the Platform offered on a subscription basis by the Company to You.</p>

  <p>
    <b>Terms and Conditions</b> (also referred as Terms) mean these Terms and Conditions that form the entire agreement between You and the
    Company regarding the use of the Platform.
  </p>

  <p>
    <b>Third-party Service</b> means any services or content (including data, information, banking products or services, or any other
    products or services) provided by a third-party that may be displayed, included or made available by the Platform.
  </p>

  <p>
    <b>Website</b> refers to Paidiem, accessible from
    <a href="https://www.paidiem.com" rel="noopener noreferrer" target="_blank"> www.paidiem.com </a>
  </p>

  <p>
    <b>You</b> means the individual accessing or using the Platform, or the company, or other legal entity on behalf of which such
    individual is accessing or using the Platform, as applicable.
  </p>

  <h5>2. Acknowledgment</h5>

  <p>
    (a) These are the Terms and Conditions governing the use of this Platform and the agreement that operates between You and the Company.
    These Terms and Conditions set out the rights and obligations of all users regarding the use of the Platform.
  </p>

  <p>
    (b) Your access to and use of the Platform is conditioned on Your acceptance of and compliance with these Terms and Conditions. These
    Terms and Conditions apply to all visitors, users and others who access or use the Platform.
  </p>

  <p>
    (c) By accessing or using the Platform You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms
    and Conditions then You may not access the Platform.
  </p>

  <p>(d) You represent that you are over the age of 18. The Company does not permit those under 18 to use the Platform.</p>

  <p>
    (e) Your access to and use of the Platform is also conditioned on Your acceptance of and compliance with the Privacy Policy of the
    Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information
    when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our
    <a href="/privacy-policy" target="_blank"> Privacy Policy </a> carefully before using Our Platform.
  </p>

  <h5>3. Use of the Services</h5>

  <h6>3.1 Your Information</h6>

  <p>
    If You to use the Services available on the Platform, You may be asked to supply and/or verify certain information relevant to you
    including, without limitation, Your name (personal or business), Your email, Your phone number, Your SIN/Social Security number, Your
    current status with tax authorities, and Your banking details.
  </p>

  <h6>3.2 Service Cancellation</h6>

  <p>We reserve the right to refuse or cancel the provision of Services at any time for certain reasons including but not limited to:</p>

  <p>Services availability</p>

  <p>Errors in the description for Services</p>

  <p>Errors in the information you provide</p>

  <p>Any misuse of the Platform</p>

  <p>
    In addition, we reserve the right to not provide services in the event that the provision of services may cause a breach of any
    applicable law, or we have grounds to suspect potential fraud or an unauthorized or illegal transaction.
  </p>

  <h6>3.3 Your Services Cancellation Rights</h6>

  <p>Any Services you purchase can only be cancelled in accordance with these Terms and Conditions.</p>

  <h6>3.4 Availability, Errors and Inaccuracies</h6>

  <p>
    (a) We are constantly updating Our offerings of Services on the Platform. The Services available on Our Platform may be], described
    inaccurately, or unavailable, and We may experience delays in updating information regarding our Services on the Platform [and in Our
    advertising on other websites.]
  </p>

  <p>
    (b) We cannot and do not guarantee the accuracy or completeness of any information, including specifications, availability, and
    services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without
    prior notice.
  </p>

  <h6>3.5 Fee Policy</h6>

  <p>(a) The Company reserves the right to revise its fees at any time in respect of the provision of Services.</p>

  <p>
    (b) The fees quoted may be revised by the Company subsequent to accepting a Request for Prepayment in the event of any occurrence
    affecting delivery caused by government action, variation in taxation, change in banking fees, higher foreign exchange costs and any
    other matter beyond the control of the Company. In that event, You will have the right to cancel Your Request for Prepayment.
  </p>

  <h6>3.6 Authorization for Payment</h6>

  <p>
    You shall provide the Company with authorization to deduct any fees for our Services from any payments available to you on the Platform.
    Should any such deduction fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed
    manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
  </p>

  <h5>4. Subscriptions</h5>

  <h6>4.1 Subscription period</h6>

  <p>
    (a) The Platform or some features of the Platform are available only with a paid Subscription. You will be billed in advance on a
    recurring and periodic basis (such as daily, weekly, monthly or annually), depending on the type of Subscription plan you select when
    purchasing the Subscription.
  </p>

  <p>
    (b) At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or the
    Company cancels it.
  </p>

  <h6>4.2 Subscription cancellations</h6>

  <p>
    You may cancel Your Subscription renewal either through Your Account settings page or by contacting the Company. You will not receive a
    refund for the fees You already paid for Your current Subscription period and You will be able to access the Platform until the end of
    Your current Subscription period.
  </p>

  <h6>4.3 Authorization for Payment</h6>

  <p>
    You shall provide the Company with authorization to deduct any fees for our Services from any payments available to you on the Platform.
    Should any such deduction fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed
    manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
  </p>

  <h6>4.4 Fee Changes</h6>

  <p>
    (a) The Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become
    effective at the end of the then-current Subscription period.
  </p>

  <p>
    (b) The Company will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate
    Your Subscription before such change becomes effective.
  </p>

  <p>
    (c) Your continued use of the Platform after the Subscription fee change comes into effect constitutes Your agreement to pay the
    modified Subscription fee amount.
  </p>

  <h6>4.5 Refunds</h6>

  <p>(a) Except when required by law, paid Subscription fees are non-refundable.</p>

  <p>
    (b) Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole
    discretion of the Company.
  </p>

  <h5>5. Intellectual Property</h5>

  <p>
    (a) The Platform and its original content (excluding Content provided by You or other users), features and functionality are and will
    remain the exclusive property of the Company and its licensors.
  </p>

  <p>
    (b) The Platform is protected by intellectual property laws and all rights as between yourself and the Company only exist for purposes
    of your use of the Platform.
  </p>

  <p>
    (c) Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the
    Company.
  </p>

  <h5>6. Your Feedback to Us</h5>

  <p>
    You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You
    agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce,
    disclose, sub-license, distribute, modify and exploit such Feedback without restriction.
  </p>

  <h5>7. Links to Other Websites</h5>

  <p>(a) Our Platform may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>

  <p>
    (b) The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party
    web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly,
    for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or
    services available on or through any such web sites or services.
  </p>

  <p>
    (c) We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You
    visit.
  </p>

  <h5>8. Termination</h5>

  <p>
    We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without
    limitation if You breach these Terms and Conditions.
  </p>

  <p>Upon termination, Your right to use the Platform will cease immediately.</p>

  <h5>9. Limitation of Liability</h5>

  <p>
    (a) Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision
    of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the
    Platform or $100 CAD if You have not purchased anything through the Platform.
  </p>

  <p>
    (b) To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special,
    incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or
    other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or
    inability to use the Platform, third-party software and/or third-party hardware used with the Platform, or otherwise in connection with
    any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the
    remedy fails of its essential purpose.
  </p>

  <p>
    (c) Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages,
    which means that some of the above limitations may not apply. In these states, each partys liability will be limited to the greatest
    extent permitted by law.
  </p>

  <h5>10. AS IS and AS AVAILABLE Disclaimer</h5>

  <p>
    (a) The Platform is provided to You AS IS and AS AVAILABLE and with all faults and defects without warranty of any kind. To the maximum
    extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective
    licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to
    the Platform, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and
    warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing,
    the Company provides no warranty or undertaking, and makes no representation of any kind that the Platform will meet Your requirements,
    achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without
    interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
  </p>

  <p>
    (b) Without limiting the foregoing, neither the Company nor any of the companys provider makes any representation or warranty of any
    kind, express or implied: (i) as to the operation or availability of the Platform, or the information, content, and materials or
    products included thereon; (ii) that the Platform will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or
    currency of any information or content provided through the Platform; or (iv) that the Platform, its servers, the content, or e-mails
    sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful
    components.
  </p>

  <p>
    (c) Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a
    consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations
    set forth in this section shall be applied to the greatest extent enforceable under applicable law.
  </p>

  <h5>11. Governing Law</h5>

  <p>
    The laws of the province of Ontario, Canada, excluding its conflicts of law rules, shall govern this Terms and Your use of the Platform.
    Your use of the Application may also be subject to other local, state, national, or international laws.
  </p>

  <h5>12. Dispute Resolution</h5>

  <p>
    If You have any concern or dispute about the Platform, You agree to first try to resolve the dispute informally by contacting the
    Company.
  </p>

  <h5>13. Changes to These Terms and Conditions</h5>

  <p>
    (a) We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make
    reasonable efforts to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be
    determined at Our sole discretion.
  </p>

  <p>
    (b) By continuing to access or use Our Platform after those revisions become effective, You agree to be bound by the revised terms. If
    You do not agree to the new terms, in whole or in part, please stop using the website and the Platform.
  </p>

  <h5>14. Contact Us</h5>

  <p>If you have any questions about these Terms and Conditions, You can contact us:</p>

  <p>By email: <a href="mailto:support@paidiem.com">support&#64;paidiem.com</a></p>
</div>
